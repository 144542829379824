import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { doc, getDoc, updateDoc ,getDocs,query,where,collection} from "firebase/firestore";
import { db, auth } from "../FireBaseConfig";

const ButtonContainer = styled.div`
    ${tw`flex justify-start gap-4 mt-8 pl-4`}
    margin-bottom: 30px;
    margin-left: 200px;
`;

const Button = styled.button`
    ${tw`px-20 py-4 text-lg rounded-lg text-white font-bold shadow-md focus:outline-none border-none`}
    ${({ variant }) =>
            variant === "pay"
                    ? tw`bg-green-1002 hover:bg-green-1001`
                    : tw`bg-red-500 hover:bg-red-600`}
`;

const ModalOverlay = styled.div`
    ${tw`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center`}
`;

const ModalContent = styled.div`
    ${tw`bg-white p-8 rounded-lg shadow-lg w-[500px] relative`}
    animation: fadeIn 0.3s ease-out;
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scale(0.9);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
`;

const ModalHeader = styled.div`
    ${tw`text-center mb-4`}
`;

const ModalTitle = styled.h2`
    ${tw`text-2xl font-bold text-gray-800`}
`;

const ModalText = styled.p`
    ${tw`text-lg text-gray-600 mb-4`}
`;

const ModalButtonGroup = styled.div`
    ${tw`flex justify-between gap-4 mt-4`}
`;

const ModalButton = styled.button`
    ${tw`flex-1 px-4 py-3 rounded-lg border-none text-white font-bold shadow-md focus:outline-none`}
    ${({ variant }) =>
            variant === "balance"
                    ? tw`bg-blue-500 hover:bg-blue-600`
                    : tw`bg-green-1002 hover:bg-green-600`}
`;

const CloseButton = styled.button`
    ${tw`absolute p-3 right-auto p-2 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none`}
`;

const Buttons = ({
                     totalCostUSD,
                     totalCostKZT,
                     insuranceAmount,
                     customDutyKZT,
                     insuranceIncluded,
                     parcelId, // ID посылки
                     servicesIds, // Массив ID услуг
                 }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [insufficientFunds, setInsufficientFunds] = useState(false);
    const [missingAmount, setMissingAmount] = useState(0);
    const navigate = useNavigate();

    const totalKZT = insuranceIncluded
        ? totalCostKZT + insuranceAmount + customDutyKZT
        : totalCostKZT + customDutyKZT;

    const handlePayWithBalance = async () => {
        try {
            const user = auth.currentUser;
            if (!user) throw new Error("Пользователь не авторизован");

            const userId = user.uid;
            const userBalanceDoc = doc(db, "balances", userId);
            const balanceSnapshot = await getDoc(userBalanceDoc);

            if (!balanceSnapshot.exists()) throw new Error("Баланс пользователя не найден");

            const userBalance = balanceSnapshot.data().balance || 0;

            if (userBalance < totalKZT) {
                navigate("/PaymentSuccess", { state: { success: false } });
                return;
            }

            await updateDoc(userBalanceDoc, { balance: userBalance - totalKZT });

            const parcelUpdated = await updateParcelStatus();
            const servicesUpdated = await updateServicesStatus();

            if (parcelUpdated && servicesUpdated) {
                navigate("/PaymentSuccess", { state: { success: true } });
            } else {
                navigate("/PaymentSuccess", { state: { success: false } });
            }
        } catch (error) {
            console.error("Ошибка при оплате:", error);
            navigate("/PaymentSuccess", { state: { success: false } });
        }
    };


    const updateParcelStatus = async () => {
        console.log("Обновление посылки с ID:", parcelId); // Лог
        if (!parcelId) {
            console.error("parcelId отсутствует!");
            return false;
        }
        try {
            // Ищем документ, где поле 'id' равно parcelId
            const querySnapshot = await getDocs(
                query(collection(db, "parcels"), where("id", "==", parcelId))
            );

            if (querySnapshot.empty) {
                console.error(`Посылка с id ${parcelId} не найдена в Firestore.`);
                return false;
            }

            // Предполагаем, что id уникально и берём первый документ
            const documentRef = querySnapshot.docs[0].ref;

            // Обновляем документ
            await updateDoc(documentRef, {
                status: "Оплачено",
                insuranceIncluded: insuranceIncluded,
                insuranceAmount: insuranceIncluded ? insuranceAmount : 0,
            });

            console.log("Статус посылки обновлен.");
            return true;
        } catch (error) {
            console.error("Ошибка при обновлении статуса посылки:", error);
            return false;
        }
    };




    const handlePayWithCard = async () => {
        try {
            console.log("Переход на PaymentForm...");
            navigate("/PaymentForm", { state: { amount: totalKZT, parcelId, servicesIds } });
        } catch (error) {
            console.error("Ошибка при переходе на PaymentForm:", error);
        }
    };



    const updateServicesStatus = async () => {
        console.log("Updating services with IDs:", servicesIds);
        try {
            const updatePromises = servicesIds.map((serviceId) => {
                const serviceDoc = doc(db, "applications", serviceId);
                return updateDoc(serviceDoc, { status: "Оплачено" });
            });
            await Promise.all(updatePromises);
            console.log("All services updated to 'Оплачено'.");
            return true;
        } catch (error) {
            console.error("Error updating service statuses:", error);
            return false;
        }
    };



    const handleCancel = () => {
        navigate("/IncomingParcels");
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <ButtonContainer>
                <Button variant="pay" onClick={() => setIsModalOpen(true)}>
                    Оплатить
                </Button>
                <Button variant="cancel" onClick={handleCancel}>
                    Отмена
                </Button>
            </ButtonContainer>

            {isModalOpen && (
                <ModalOverlay>
                    <ModalContent>
                        <CloseButton onClick={handleCloseModal}>
                            <FaTimes size={16} />
                        </CloseButton>
                        <ModalHeader>
                            <ModalTitle>Выберите способ оплаты</ModalTitle>
                            <ModalText>
                                Итоговая сумма: <span tw="font-bold">{totalKZT.toFixed(2)} ₸</span> ({Number(totalCostUSD).toFixed(2)} $)
                            </ModalText>
                        </ModalHeader>
                        <ModalButtonGroup>
                            <ModalButton variant="balance" onClick={handlePayWithBalance}>
                                С баланса
                            </ModalButton>
                            <ModalButton variant="card" onClick={handlePayWithCard}>
                                С карты
                            </ModalButton>
                        </ModalButtonGroup>
                    </ModalContent>
                </ModalOverlay>
            )}

            {insufficientFunds && (
                <ModalOverlay>
                    <ModalContent>
                        <CloseButton onClick={() => setInsufficientFunds(false)}>
                            <FaTimes size={16} />
                        </CloseButton>
                        <ModalHeader>
                            <ModalTitle>Недостаточно средств</ModalTitle>
                            <ModalText>
                                Вам не хватает <span tw="font-bold">{missingAmount.toFixed(2)} ₸</span> для оплаты. Пополните баланс.
                            </ModalText>
                        </ModalHeader>
                        <ModalButtonGroup>
                            <ModalButton variant="balance" onClick={() => navigate("/PaymentForm")}>
                                Пополнить баланс
                            </ModalButton>
                        </ModalButtonGroup>
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
};

export default Buttons;
