import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle } from 'styled-components';
import { ReactComponent as PhotoIcon } from './images/icon/Photo.svg';

import { ReactComponent as Service } from './images/icon/service.svg';
import AnimationRevealPage from "./helpers/AnimationRevealPage";
import MainCabinetHeader from "./components/headers/MainCabinetHeader";
import Footer from "./components/footers/MainFooterWithLinks";
import tw from "twin.macro";
import { getAuth } from 'firebase/auth';
import {collection, addDoc, getDocs, query, where, doc, getDoc, getFirestore} from 'firebase/firestore';
import { db } from './FireBaseConfig';
import { useNavigate } from 'react-router-dom';
const GlobalStyle = createGlobalStyle`
    select, option {
        color: #000 !important;
        background-color: #fff !important;
    }
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(45, 45, 45, 0.08);
    overflow: hidden;
    margin: 20px auto;
    width: 90%;
    max-width: 1300px;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    margin-top: 60px;
`;

const SidebarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    background: #ffffff;
    border-right: 1px solid #F5F5F5;
    @media (min-width: 768px) {
        width: 250px;
    }
`;

const TabButton = styled.button`
    display: flex;
    align-items: center;
    background: ${({ active }) => (active ? '#DDF2E6' : 'transparent')};
    color: #000000;
    border: none;
    padding: 10px 22px;
    font-size: 16px;
    line-height: 18px;
    font-family: 'Gilroy Medium', sans-serif;
    cursor: pointer;
    text-align: left;
    &:hover {
        background-color: #DDF2E6;
    }
    svg {
        margin-right: 20px;
        width: 30px;
        height: 30px;
    }
`;

const IconContainer = styled.span`
    display: flex;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 24px;
    line-height: 29px;
    color: #2D2D2D;
    margin-bottom: 20px;
    font-weight: 700;
    font-family: 'Gilroy Medium', sans-serif;
`;

const StyledParagraph = styled.p`
    padding: 0;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 30px;
    color: #2D2D2D;
    font-family: 'Gilroy Medium', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    outline: none;
    cursor: default;
    text-align: left;
`;

const Highlight = styled.span`
    color: #0ABD19;
    font-weight: bold;
    font-size: 25px;
`;

const FormGroup = styled.div`
    ${tw`relative flex flex-col mb-6`}
`;

const Label = styled.label`
    ${tw`mt-1 sm:mt-0 mb-2 font-medium text-secondary-100 leading-loose`}
`;


const CustomSelect = styled.select`
    ${tw`mt-2 w-full px-4 py-3 bg-white text-left rounded-md shadow-sm border border-gray-300 cursor-pointer`}
    color: #000 !important; /* Убедитесь, что текст черного цвета */
    background-color: #fff !important; /* Убедитесь, что фон белый */
    font-family: inherit;

    & option {
        color: #000 !important; /* Текст черного цвета */
        background-color: #fff !important; /* Белый фон */
    }
`;


const Input = styled.input`
    ${tw`mt-2 first:mt-0  focus:outline-none font-medium transition duration-300 border-b-2`}
    color: #6c757d;
    border-color: transparent;
    border-bottom-color: #adb5bd;
    font-family: inherit;
    width: calc(100% - 50px);
    &::placeholder {
        color: #adb5bd;
    }
    &:hover {
        border-bottom-color: #0ABD19;
    }
    &:focus {
        border-bottom-color: #0ABD19;
    }
`;

const CustomOptions = styled.ul`

`;

const FormContainer = styled.div`
    ${tw`flex justify-center items-center flex-grow py-4`}
`;
const CustomListItem = styled.li`
    ${tw`px-4 py-3 text-black cursor-pointer`}
    &:hover {
        background: #f3f4f6;
    }
    ${({ isSelected }) => isSelected && tw`bg-gray-200`}
`;


const Form = styled.form`
    ${tw`grid grid-cols-1 gap-4 `}
    width: 100%;
    height: 100%;
`;

const StyledContainer = styled.div`
    ${tw`w-full max-w-3xl p-4 bg-white shadow-lg rounded-lg border`}
    border: 2px solid #0ABD19;
    border-radius: 15px;
    padding: 20px 20px;
    margin: 20px auto;
    overflow: hidden;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 15px;
    justify-content: center;
    margin-top: 20px;
`;

const SubmitButton = styled.button`
    ${tw`px-8 py-3 font-medium text-white rounded-full focus:outline-none transition-transform duration-300`}
    background-color: #0ABD19;
    text-decoration: none;
    &:hover {
        background-color: #0ABD50;
        transform: scale(1.05);
    }
`;

const CustomOption = styled.li`
    ${tw`px-4 py-3 text-black cursor-pointer`}
    &:hover {
        background: #f3f4f6;
    }
    ${({ isSelected }) => isSelected && tw`bg-gray-200`}
`;

const ResetButton = styled.button`
    ${tw`px-8 py-3 font-medium text-white rounded-full focus:outline-none transition-transform duration-300`}
    background-color: #0ABD19;
    text-decoration: none;
    &:hover {
        background-color: #0ABD50;
        transform: scale(1.05);
    }
`;

const ContentContainer = styled.div`
    flex: 1;
    padding: 20px;
    transition: opacity 0.5s ease;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`;
const CustomSelectWrapper = styled.div`
    position: relative;
    width: 93%;
`;

const tabs = [
    {
        name: "Фото товаров в посылке",
        icon: PhotoIcon,
        service: "Фото в посылке",
        content: "Для оформления данной услуги. Вам необходимо зайти в личный кабинет, в разделе «услуги склада» выбрать нужную вам посылку, затем нажать на услугу «фото товаров в посылке». В течение 24-48 часов мы выполним данную услугу. В стоимость данной услуги входит 3-5 фотографий всех товаров в посылке, а также Ваши товары сверяются на общее соответствие с описанием в личном кабинете. В случае несоответствия товаров мы уведомим Вас об этом в личном кабинете. Стоимость услуги – 5$ (3-5 фото)"
    },

    {
        name: "Дополнительная услуга",
        icon: Service,
        service: "Дополнительная услуга",
        content: "Дополнительные услуги для вашего удобства стоимость 5$ "
    },
];

export default function App() {
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [parcels, setParcels] = useState([]);

    const [activeContent, setActiveContent] = useState(tabs[0].content);
    const [isActive, setIsActive] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        setIsActive(false);
        setTimeout(() => {
            setActiveContent(tabs[activeTab].content);
            setIsActive(true);
        }, 500);
    }, [activeTab]);
    useEffect(() => {
        const fetchParcels = async () => {
            setLoading(true); // Устанавливаем состояние загрузки
            const auth = getAuth();
            const user = auth.currentUser;
            const db = getFirestore();

            if (user) {
                try {
                    const userDocRef = doc(db, 'users', user.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const correctUserId = userData.userId;

                        // Фильтруем посылки по userId и статусу "Создана"
                        const q = query(
                            collection(db, 'parcels'),
                            where("userId", "==", correctUserId),
                            where("status", "==", "Создана") // Условие для фильтрации по статусу
                        );
                        const querySnapshot = await getDocs(q);

                        const fetchedParcels = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                        const uniqueParcels = Array.from(new Map(fetchedParcels.map(parcel => [parcel.id, parcel])).values());
                        console.log("Доступные посылки для пользователя со статусом 'Создана':");
                        setParcels(uniqueParcels);
                        uniqueParcels.forEach(parcel => console.log(parcel.id));
                    }
                } catch (error) {
                    console.error("Error fetching parcels:", error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchParcels();
    }, []);






    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const form = event.target;
            const data = new FormData(form);
            const packageId = data.get('package');
            const comments = data.get('comments');

            console.log("ID посылки, переданное в запрос:", packageId);
            console.log("Комментарий:", comments);

            try {
                // Поиск документа по полю "id" с использованием запроса query и where
                const q = query(collection(db, 'parcels'), where('id', '==', packageId));
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    console.error(`Посылка с ID ${packageId} не найдена в базе данных.`);
                    throw new Error("Посылка не найдена");
                } else {
                    const packageData = querySnapshot.docs[0].data();
                    console.log("Данные посылки:", packageData);

                    // Добавляем данные о заявке в коллекцию applications
                    await addDoc(collection(db, 'applications'), {
                        userId: user.uid, // ID пользователя
                        service: tabs[activeTab].service, // Название услуги
                        packageId: packageId, // ID посылки
                        trackingNumber: packageData.trackingNumber || "Не указан", // Трек-номер посылки
                        comments: comments, // Комментарий оператора
                        country: packageData.warehouse || "Unknown", // Страна/склад
                        timestamp: new Date(), // Дата создания
                        status: "Создана", // Статус заявки по умолчанию
                        cost: 5, // Стоимость услуги
                    });

                    alert('Заявка успешно создана');
                    form.reset();

                    // Редирект на PersonalArea
                    navigate('/PersonalArea');
                }
            } catch (error) {
                console.error("Ошибка создания заявки:", error);
                alert("Ошибка при создании заявки. Пожалуйста, попробуйте снова.");
            }
        }
    };



    return (
        <AnimationRevealPage>
            <GlobalStyle />
            <MainCabinetHeader />
            <Layout>
                <SidebarContainer>
                    {tabs.map((tab, index) => (
                        <TabButton
                            key={index}
                            active={index === activeTab}
                            onClick={() => setActiveTab(index)}
                        >
                            <IconContainer>{<tab.icon active={index === activeTab} />}</IconContainer>
                            {tab.name}
                        </TabButton>
                    ))}
                </SidebarContainer>
                <ContentContainer isActive={isActive}>
                    <StyledParagraph>{tabs[activeTab].content}</StyledParagraph>
                    <Highlight>Заявка на услугу склада - {tabs[activeTab].name}</Highlight>
                    <StyledContainer>
                        <FormContainer>
                            <Form id={`${tabs[activeTab].service}-form`} onSubmit={handleFormSubmit}>
                                <FormGroup>
                                    <Label htmlFor="package">Выберите посылку</Label>
                                    <CustomSelectWrapper>
                                        <CustomSelect name="package">
                                            {parcels.map((parcel) => (
                                                <option key={parcel.id} value={parcel.id}>
                                                    {parcel.id} - {parcel.parcelName || "Без названия"}
                                                </option>
                                            ))}
                                        </CustomSelect>
                                    </CustomSelectWrapper>
                                </FormGroup>


                                <FormGroup>
                                    <Label htmlFor="comments">Комментарий для оператора</Label>
                                    <Input id="comments" name="comments" type="text" />
                                </FormGroup>
                            </Form>
                        </FormContainer>
                    </StyledContainer>
                    <ButtonContainer>
                        <SubmitButton type="submit" form={`${tabs[activeTab].service}-form`}>Отправить заявку</SubmitButton>
                        <ResetButton type="reset" onClick={() => document.getElementById(`${tabs[activeTab].service}-form`).reset()}>Сбросить</ResetButton>
                    </ButtonContainer>
                </ContentContainer>
            </Layout>
            <Footer />
        </AnimationRevealPage>
    );
}