import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter as Router, Route, Routes, Navigate, useLocation, matchPath} from "react-router-dom";
import {AuthProvider, useAuth} from "./AuthContext";
import ConditionalHeader from "components/headers/ConditionalHeaderComponent";
import App from "./App";
import LoginPage from "./LoginPage";
import {ToastContainer, toast} from 'react-toastify';
import EditExpectedLink from "./EdirParcelCabinet";
import RegistrationPage from "./RegistrationPage";
import AboutUS from "./AboutUS";
import Tariffs from "./tariffs";
import {UserProvider} from "./UserContext";
import Shops from "./Shops";
import RedemptionOfGoods from "./RedemptionOfGoods";
import Contacts from "./Contacts";
import LegalInformation from "./pages/LegalInformation";
import Deliveryaddress from "./Deliveryaddress";
import DeliveryCalculator from "./pages/DeliveryCalculator";
import Purchaseofgoods from "./Purchaseofgoods";
import CustomsRegulations from "./pages/CustomsRegulations";
import ProhibitedProductsPage from "./pages/ProhibitedProductsPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PublicOffer from "./pages/PublicOffer";
import TermsOfService from "./pages/TermsOfService";
import BrandedHandbagsPage from "./pages/BrandedHandbagsPage";
import Rates from "./Rates";
import DutyCalculatorPage from "./pages/DutyCalculatorPage";
import HowToBuyGoodsAbroadPage from "./pages/HowToBuyGoodsAbroadPage";
import ClothesSizes from "./pages/ClothesSizes";
import ForBussinesPage from "./pages/ForBussinesPage";
import News from "./News";
import {PaymentProvider} from './components/cards/PaymentContext';
import Questions from "./Questions";
import ServiseApplication from "./ServiseApplication";
import RecipientsPrivateCabinet from "./pages/RecipientsPrivateCabinet";
import ExpectedLink from "./ExpectedLink";
import SinglePage from "./SinglePageApplication";
import RecipientsForm from "./RecipientsForm";
import IncomingParcels from "./pages/IncomingParcels";
import IncomingPParcels from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/IncomingPParcels";
import OutgoingParcels from "./pages/OutgoingParcels";
import DeliveredParcels from "./pages/DeliveredParcels";
import WarehouseServices from "./pages/WarehouseServices";
import ChangePassword from "./pages/ChangePassword";
import OutgoingParcelForm from "./OutgoingParcelForm";
import ChangingContactDetails from "./pages/ChangingContactDetails";
import PersonalArea from "./pages/PersonalArea";
import AdminPage from "WarehouseAccounting/WareHouseMainPage";
import ForgetThePasswordPage from "./pages/ForgetThePasswordPage";
import PaymentForm from "./components/cards/Payment";
import ExpectedParcels from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/ExpectedParcels";
import WareHouseMainPage from "WarehouseAccounting/WareHouseMainPage";
import Parsed from "WarehouseAccounting/SubpagesOfTheWarehouseItem/parsed"
import EditingParcel from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/EditingParcel";
import ProccessingParcels from "./WarehouseAccounting/processinparcel";
import FormProcessing from "./WarehouseAccounting/components/FormsForEditing/FormProcessing";
import SendParcelForm from "./SendParcel/SendParcelForm";
import Step4and5Step from "./SendParcel/Step4and5";
import PaymentSuccess from "./SendParcel/PaymentSuccess";
import {ParcelProvider} from "./context/ParcelContext";
import PaidParcel from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/PaidParcel"
import AddPalet from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/AddPalet"
import ListPalet from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/ListPalet";
import AddPaletParcel from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/AddParcelPalets"
import AcceptesParcel from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/AcceptesParcel";
import Shipment from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/Shipment";
import AddPalletShioment from "./WarehouseAccounting/SubpagesOfTheWarehouseItem/AddPalletShioment";
// Компонент для защиты маршрутов
const ProtectedRoute = ({children, role}) => {
    const {user} = useAuth();

    if (!user) {
        console.log('No user found, redirecting to login');
        return <Navigate to="/login" replace/>;
    }

    if (role === "admin" && user.email !== "admin@gmail.com") {
        console.log('Not authorized as admin, redirecting to login');
        return <Navigate to="/login" replace/>;
    }

    return children;
};

// Компонент для маршрутизации
const AppRoutes = () => {
    const location = useLocation();

    // Обновлённый массив маршрутов для скрытия заголовка
    const hideHeaderOnRoutes = ["/login", "/RegistrationPage"];

    // Обновлённый массив маршрутов для администратора, включая параметры
    const adminRoutes = ["/admin",
        "/WareHouseMainPage",
        "/ExpectedParcels",
        "/IncomingPParcels",
        "/Parsed",
        "/EditingParcel/:parcelId",
        "/ProccessingParcels",
        "/FormProcessing",
        "/paid",
        "/add-pallet",
        "/pallet-list",
        "/add-parcel-pallet",
        "/add-parcel-pallet/:palletId",
        "/acceptedparcels",
        "/create-shipment",
        "/input-shipment"];

    // Проверка на скрытие заголовка
    const shouldHideHeader = hideHeaderOnRoutes.some(route => matchPath(route, location.pathname));

    // Проверка на администраторские маршруты
    const isAdminRoute = adminRoutes.some(route => matchPath(route, location.pathname));

    return (<>
        {/* Отображение ConditionalHeader, если не нужно скрывать заголовок и это не админский маршрут */}
        {!shouldHideHeader && !isAdminRoute && <ConditionalHeader/>}
        <Routes>
            <Route path="/Login" element={<LoginPage/>}/>
            <Route path="/RegistrationPage" element={<RegistrationPage/>}/>
            <Route path="/SendParcelForm" element={<SendParcelForm/>}/>
            <Route path="/" element={<App/>}/>
            <Route path="/App" element={<App/>}/>
            <Route path="/Tariffs" element={<Tariffs/>}/>
            <Route path="/Shops" element={<Shops/>}/>
            <Route path="/RedemptionOfGoods" element={<RedemptionOfGoods/>}/>
            <Route path="/AboutUS" element={<AboutUS/>}/>
            <Route path="/Contacts" element={<Contacts/>}/>
            <Route path="/PaymentForm" element={<PaymentForm/>}/>
            <Route path="/RecipientsForm" element={<RecipientsForm/>}/>
            <Route path="/Deliveryaddress" element={<Deliveryaddress/>}/>
            <Route path="/OutgoingParcels" element={<OutgoingParcels/>}/>
            <Route path="/DeliveredParcels" element={<DeliveredParcels/>}/>
            <Route path="/WarehouseServices" element={<WarehouseServices/>}/>
            <Route path="/IncomingParcels" element={<IncomingParcels/>}/>
            <Route path="/OutgoingParcelForm" element={<OutgoingParcelForm/>}/>
            <Route path="/PersonalArea" element={<PersonalArea/>}/>
            <Route path="/ExpectedLink" element={<ExpectedLink/>}/>
            <Route path="/ServisApplication" element={<ServiseApplication/>}/>
            <Route path="/Purchaseofgoods" element={<Purchaseofgoods/>}/>
            <Route path="/IncomingPParcels" element={<IncomingPParcels/>}/>
            <Route path="/ChangePassword" element={<ChangePassword/>}/>
            <Route path="/test/:id" element={<ExpectedLink/>}/>
            <Route path="/edit/:documentId" element={<EditExpectedLink/>}/>
            <Route path="/ChangingContactDetails" element={<ChangingContactDetails/>}/>
            <Route path="/RecipientsPrivateCabinet" element={<RecipientsPrivateCabinet/>}/>
            <Route path="/CustomRegulations" element={<CustomsRegulations/>}/>
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
            <Route path="/TermsOfService" element={<TermsOfService/>}/>
            <Route path="/PublicOffer" element={<PublicOffer/>}/>
            <Route path="/ForgetThePasswordPage" element={<ForgetThePasswordPage/>}/>
            <Route path="/ProhibitedProductsPage" element={<ProhibitedProductsPage/>}/>
            <Route path="/DutyCalculatorPage" element={<DutyCalculatorPage/>}/>
            <Route path="/DeliveryCalculator" element={<DeliveryCalculator/>}/>
            <Route path="/LegalInformation" element={<LegalInformation/>}/>
            <Route path="/HowToBuyGoodsAbroadPage" element={<HowToBuyGoodsAbroadPage/>}/>
            <Route path="/ClothesSizes" element={<ClothesSizes/>}/>
            <Route path="/ForBussinesPage" element={<ForBussinesPage/>}/>
            <Route path="/Rates" element={<Rates/>}/>
            <Route path="/BrandedHandbagsPage" element={<BrandedHandbagsPage/>}/>
            <Route path="/News" element={<News/>}/>
            <Route path="/Questions" element={<Questions/>}/>
            <Route path="/PaymentSuccess" element={<PaymentSuccess/>}/>
            <Route path="/SendParcelForm/:id" element={<SendParcelForm/>}/>

            {/* Защищенные маршруты для администратора */}
            <Route path="/admin" element={<ProtectedRoute role="admin">
                <AdminPage/>
            </ProtectedRoute>}/>
            <Route path="/WareHouseMainPage" element={<ProtectedRoute role="admin">
                <WareHouseMainPage/>
            </ProtectedRoute>}/>
            <Route path="/Parsed" element={<ProtectedRoute role="admin">
                <Parsed/>
            </ProtectedRoute>}/>
            <Route path="/ExpectedParcels" element={<ProtectedRoute role="admin">
                <ExpectedParcels/>
            </ProtectedRoute>}/>
            <Route path="/EditingParcel/:parcelId" element={<ProtectedRoute role="admin">
                <EditingParcel/>
            </ProtectedRoute>}/>
            <Route path="/ProccessingParcels" element={<ProtectedRoute role="admin">
                <ProccessingParcels/>
            </ProtectedRoute>}/>
            <Route path="/FormProcessing" element={<ProtectedRoute role="admin">
                <FormProcessing/>
            </ProtectedRoute>}/>
            <Route path="/paid" element={<ProtectedRoute role="admin">
                < PaidParcel/>
            </ProtectedRoute>}/>
            <Route path="/add-pallet" element={<ProtectedRoute role="admin">
                <AddPalet/>
            </ProtectedRoute>

            }/>
            <Route path="/pallet-list" element={<ProtectedRoute role="admin">
                <ListPalet/>
            </ProtectedRoute>}/>
            <Route path="/add-parcel-pallet" element={<ProtectedRoute role="admin">
                <AddPaletParcel/>
            </ProtectedRoute>}/>
            <Route path="/add-parcel-pallet/:palletId"
                   element={<ProtectedRoute role="admin"><AddPaletParcel/></ProtectedRoute>}/>
            <Route path="/acceptedparcels" element={<ProtectedRoute role="admin"><AcceptesParcel/></ProtectedRoute>}/>
            <Route path="/create-shipment" element={<ProtectedRoute role="admin">
                <Shipment/>
            </ProtectedRoute>}/>
            <Route path="/input-shipment" element={<ProtectedRoute role="admin">
                <AddPalletShioment/>
            </ProtectedRoute>}/>
        </Routes>

    </>);
};

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<AuthProvider>
    <UserProvider>
        <Router>
            <PaymentProvider>
                <ParcelProvider>

                    <AppRoutes/>
                </ParcelProvider>
            </PaymentProvider>
        </Router>
    </UserProvider>
</AuthProvider>);
