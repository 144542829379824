import React, {useEffect, useState} from "react";
import tw, {styled} from "twin.macro";
import {ExpecteLink} from "../components/misc/Headings";
import {useParams} from "react-router-dom";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../FireBaseConfig";
import Buttons from "./Buttons"; // Импорт кнопок
const FormContainer = styled.div`
    ${tw`flex justify-center items-center flex-grow`};
`;
const PageTitle = styled.h1`
    ${tw`text-3xl font-bold `};
    text-align: left;
`;
const HighlightedText = styled.span`
    ${tw`text-primary-500`};
    color: #0ABD19;
`;
const Container = styled.div`
    ${tw`w-full max-w-[70rem] p-8 bg-white shadow-lg rounded-lg border`};
    border: 2px solid #1BA557;
    border-radius: 15px;
    padding: 40px;
    display: grid;
    margin-top: 0px;
    grid-template-columns: 1fr 1fr; /* Two columns */
    column-gap: 60px; /* Bigger gap between columns for better spacing */
`;

const Column = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
`;
const ColumnLeft = styled.div`
    ${tw`text-gray-600 font-semibold`};
    text-align: left;
`;

const ColumnRight = styled.div`
    ${tw`text-gray-900 font-normal`};
    text-align: right;
`;


const StepTitle = tw(ExpecteLink)`w-full mt-2 mb-4`;
const Step1 = ({ onDataPass }) => {
    const [parcelData, setParcelData] = useState(null);
    const { id } = useParams(); // Получаем id из URL
    const [loading, setLoading] = useState(true); // Изн
    const [services, setServices] = useState([]);// ачально загружаем
    const [exchangeRate, setExchangeRate] = useState(0);
    const fetchExchangeRate = async () => {
        try {
            const response = await fetch(`https://open.er-api.com/v6/latest/USD`);
            const data = await response.json();
            if (data && data.rates && data.rates.KZT) {
                setExchangeRate(data.rates.KZT);
            } else {
                console.error("Не удалось получить курс валют.");
                setExchangeRate(450); // Фиксированный курс в случае ошибки
            }
        } catch (error) {
            console.error("Ошибка при получении курса валют:", error);
            setExchangeRate(450); // Фиксированный курс
        }
    };
    async function fetchParcelData(parcelId) {
        const cleanId = parcelId.trim();
        console.log(`Fetching parcel with ID: ${cleanId}`);

        try {
            const q = query(collection(db, 'parcels'), where('id', '==', cleanId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    setParcelData(doc.data());
                });
            } else {
                console.error(`No parcel found with this ID: ${cleanId}`);
            }
        } catch (error) {
            console.error("Error fetching parcel:", error);
        } finally {
            setLoading(false); // Убираем состояние загрузки
        }
    }
    async function fetchServices(parcelId) {
        try {
            const q = query(collection(db, "applications"), where("packageId", "==", parcelId));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const fetchedServices = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setServices(fetchedServices);
            } else {
                console.log("No services found for this parcel.");
            }
        } catch (error) {
            console.error("Error fetching services:", error);
        }
    }

    useEffect(() => {
        // Получение данных при изменении id
        if (id) {
            setLoading(true); // Активируем состояние загрузки
            fetchExchangeRate(); // Загружаем курс валют
            fetchParcelData(id);
            fetchServices(id);
        }
    }, [id]);

    useEffect(() => {
        if (parcelData && exchangeRate) {
            // Суммируем стоимость всех дополнительных услуг в USD
            const additionalServicesCostUSD = services.reduce((sum, service) => sum + service.cost, 0);

            // Считаем общую стоимость товаров и услуг в USD
            const totalCostUSD = parcelData.totalCost + additionalServicesCostUSD;

            // Конвертируем сумму дополнительных услуг в тенге
            const additionalServicesCostKZT = Math.round(additionalServicesCostUSD * exchangeRate);

            // Конвертируем общую стоимость в тенге (KZT)
            const totalCostKZT = Math.round(totalCostUSD * exchangeRate);

            // Передаем итоговые данные через onDataPass
            console.log("Стоимость товаров (USD):", parcelData.totalCost);
            console.log("Стоимость дополнительных услуг (USD):", additionalServicesCostUSD);
            console.log("Общая стоимость (USD):", totalCostUSD);
            console.log("Сумма услуг (KZT):", additionalServicesCostKZT);
            console.log("Общая стоимость (KZT):", totalCostKZT);

            onDataPass({
                totalCostUSD,
                totalCostKZT,
                additionalServicesCostUSD,
                additionalServicesCostKZT,
            });
        }
    }, [parcelData, services, exchangeRate]);

    useEffect(() => {
        if (parcelData) {
            const servicesIds = services.map((service) => service.id);
            console.log("Передача данных в onDataPass:", { parcelId: id, servicesIds, parcelData }); // Лог для проверки
            onDataPass({ parcelId: id, servicesIds, parcelData });
        }
    }, [parcelData, services, id]);



    return (
        <FormContainer>
            <div style={{width: '100%', maxWidth: '1280px'}}>
                <PageTitle active={true}>Заявка на отправку посылки в Казахстан</PageTitle>
                <StepTitle active={true}>
                    <HighlightedText>Шаг 1. </HighlightedText>Проверьте состав посылки
                </StepTitle>
                {loading ? (
                    <div>Загрузка...</div>
                ) : parcelData ? (
                    <Container>
                        <div>
                            <Column>
                                <ColumnLeft>Номер посылки:</ColumnLeft>
                                <ColumnRight>{parcelData.id}</ColumnRight>
                            </Column>

                            <Column>
                                <ColumnLeft>Входящий трэк №:</ColumnLeft>
                                <ColumnRight>{parcelData.trackingNumber}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Наименование:</ColumnLeft>
                                <ColumnRight>{parcelData.parcelName}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Дополнительные услуги:</ColumnLeft>
                                <ColumnRight>
                                    {services.length > 0 ? (
                                        services.map((service) => (
                                            <div key={service.id}>
                                                {Math.round(service.cost * exchangeRate)}₸ (${service.cost})
                                            </div>
                                        ))
                                    ) : (
                                        "Нет услуг"
                                    )}
                                </ColumnRight>
                            </Column>
                        </div>
                        <div>
                            <Column>
                                <ColumnLeft>Стоимость товаров:</ColumnLeft>
                                <ColumnRight>{parcelData.totalCost}$</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Количество товаров в посылке:</ColumnLeft>
                                <ColumnRight>{parcelData.totalQuantity}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Вес посылки:</ColumnLeft>
                                <ColumnRight>{parcelData.width}</ColumnRight>
                            </Column>
                            <Column>
                                <ColumnLeft>Габариты (В*Ш*Д):</ColumnLeft>
                                <ColumnRight>
                                    {parcelData.height} * {parcelData.width} * {parcelData.length}
                                </ColumnRight>
                            </Column>
                        </div>
                    </Container>
                ) : (
                    <div>Нет данных о посылке</div>
                )}
            </div>
        </FormContainer>
    );
};

export default Step1;