import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    box-shadow: none;
    transform: rotate(0);
    border-top-color: #fff;
  }
  20% {
    box-shadow: none;
    transform: rotate(360deg);
    border-top-color: #fff;
  }
  40% {
    box-shadow: none;
    transform: rotate(720deg);
    border-top-color: #fff;
  }
  60% {
    box-shadow: none;
    transform: rotate(1080deg);
    border-top-color: #fff;
  }
  80%, 100% {
    transform: rotate(1080deg);
    box-shadow: 0 0 0 20px inset #fff, 0 2px 3px rgba(0, 0, 0, 0.1);
    border-top-color: transparent;
  }
`;

const rotateFirst = keyframes`
  0% {
    transform: none;
  }
  100% {
    transform: translateY(-120%);
    opacity: 0;
  }
`;

const rotateSecond = keyframes`
    0% {
        transform: translateY(120%);
        opacity: 0;
    }
    20%, 80% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-120%);
        opacity: 0;
    }
`;

const rotateThird = keyframes`
    0% {
        transform: translateY(240%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
`;

const beGreen = keyframes`
    0% {
        background:  #0ABD19;
        box-shadow: 0 0 20px rgb(152, 0, 0);
    }
    100% {
        background:  #0ABD19;
        box-shadow: 0 0 20px rgba(42, 192, 106, 0.5);
    }
`;

const iconBeGreen = keyframes`
    from {
        color: #ff0000;
    }
    to {
        color:  #0ABD19;
    }
`;
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh; /* Уменьшено */
    font-family: "Nunito", Arial, sans-serif;
    margin-top: 30px;
`;

const Checkbox = styled.input`
    display: none;

    &:checked + label {
        animation: ${beGreen} 0.2s ease 1 forwards;
        animation-delay: 3.5s;

        .check-icon {
            box-shadow: 0 0 0 0 inset;
            border: 2px solid transparent;
            border-top-color: #fff;
            animation: ${spin} 5s ease 1 forwards;
            animation-delay: 0.2s;

            .fa-arrow-up {
                transform: translate(-50%, -230%);
            }

            .fa-check {
                transform: translate(-50%, -50%);
                animation: ${iconBeGreen} 0.1s ease 1 forwards;
                animation-delay: 3.5s;
            }
        }

        .btn-text p {
            &:first-of-type {
                animation: ${rotateFirst} 0.3s cubic-bezier(0.2, 0.5, 0.2, 1.2) 1 forwards;
            }

            &:nth-of-type(2) {
                animation: ${rotateSecond} 4.3s cubic-bezier(0.2, 0.5, 0.2, 1.2) 1 forwards;
            }

            &:nth-of-type(3) {
                animation: ${rotateThird} 0.3s cubic-bezier(0.2, 0.5, 0.2, 1.2) 1 forwards;
                animation-delay: 3.6s;
            }
        }
    }
`;

const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0; /* Уменьшено */
    width: 100px; /* Уменьшено */
    background: #6a6a6a;
    box-shadow: 0 0 10px rgb(96, 96, 96); /* Уменьшено */
    color: #ffffff;
    border-radius: 20px; /* Уменьшено */
    cursor: pointer;
    transition: all 0.2s ease;
    height: 30px; /* Уменьшено */

    &:hover .check-icon .fa-arrow-up {
        transform: translate(-50%, -230%);
    }

    &:hover .check-icon .fa-check {
        transform: translate(-50%, -50%);
    }

    &:active {
        transform: scale(0.9);
    }
`;

const CheckIcon = styled.div`
    position: relative;
    width: 20px; /* Уменьшено */
    height: 20px; /* Уменьшено */
    border-radius: 50%;
    box-shadow: 0 0 0 10px inset #fff, 0 1px 2px rgba(0, 0, 0, 0.1); /* Уменьшено */
    overflow: hidden;
    margin-left: 8px; /* Уменьшено */
    transition: all 0.3s ease;
    border: 2px solid transparent;

    .fa-arrow-up,
    .fa-check {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.6rem; /* Уменьшено */
        color: #6a6a6a;
        transition: all 0.4s cubic-bezier(0.2, 0.5, 0.2, 1.2);
    }

    .fa-check {
        transform: translate(-50%, 130%);
    }
`;

const BtnText = styled.div`
    font-size: 0.7rem; /* Уменьшено */
    width: 50px; /* Уменьшено */
    height: 15px; /* Уменьшено */
    margin-right: 10px; /* Уменьшено */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    p {
        position: absolute;
        transition: all 0.2s ease;
        display: block;

        &:nth-of-type(2) {
            transform: translateY(120%);
        }

        &:nth-of-type(3) {
            transform: translateY(240%);
        }
    }
`;




const AnimatedButton = ({ active, onClick }) => {
    return (
        <ButtonWrapper>
            <Checkbox
                type="checkbox"
                id="checkbox-activate"
                onChange={onClick} // Обработка клика
                checked={active} // Состояние кнопки
            />
            <Label htmlFor="checkbox-activate">
                <CheckIcon className="check-icon">
                    <i className="fas fa-arrow-up"></i>
                    <i className="fas fa-check"></i>
                </CheckIcon>
                <BtnText className="btn-text">
                    <p>{active ? "Убрать" : "Добавить"}</p>
                    <p>Waiting</p>
                    <p>Activated</p>
                </BtnText>
            </Label>
        </ButtonWrapper>
    );
};

export default AnimatedButton;
