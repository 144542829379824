import React, { useState } from "react";
import Step3Backend from "./STEP3";
import tw from "twin.macro";
import styled from "styled-components";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import MainCabinetHeader from "../components/headers/MainCabinetHeader";
import Step1 from "./step1";
import Step2 from "./STEP2";
import Step4and5Step from "./Step4and5";
import Button from "./Buttons";
import Footer from "components/footers/MainFooterWithLinks";
const PageContainer = styled.div`
    ${tw`min-h-screen flex flex-col`};
`;

const ExpectedLink = () => {
    const [paymentData, setPaymentData] = useState({
        totalCostUSD: 0,
        totalCostKZT: 0,
        insuranceAmount: 0, // Для передачи суммы страхования
        customDutyKZT: 0, // Для передачи таможенной пошлины
    });
    const [insuranceIncluded, setInsuranceIncluded] = useState(false);

    const [parcelData, setParcelData] = useState({}); // Данные посылки
    const [servicesIds, setServicesIds] = useState([]); // IDs услуг
    const [parcelId, setParcelId] = useState(null); // ID посылки

    // Обработка данных из Step1
    const handleDataPassFromStep1 = ({ parcelId, servicesIds, parcelData }) => {
        setParcelId(parcelId);
        setServicesIds(servicesIds);
        setParcelData(parcelData);
    };
    const handleDataPass = (data) => {
        setPaymentData((prevState) => ({
            ...prevState,
            ...data,
        }));
    };

    const handleInsuranceUpdate = (data) => {
        if (!data || typeof data !== "object") {
            console.error("Получены неверные данные:", data);
            return;
        }

        setPaymentData((prevState) => ({
            ...prevState,
            totalCostKZT: data.totalCostKZT || prevState.totalCostKZT,
            insuranceAmount: data.insuranceAmount || prevState.insuranceAmount,
            customDutyKZT: data.customDutyKZT || prevState.customDutyKZT,
        }));

        if (data.insuranceIncluded !== undefined) {
            setInsuranceIncluded(data.insuranceIncluded);
        }
    };



    return (
        <AnimationRevealPage>
            <MainCabinetHeader />
            <PageContainer>
                <Step1
                    onDataPass={(data) => {
                        handleDataPass(data);
                        handleDataPassFromStep1(data);
                    }}
                />

                <Step2 />
                <Step3Backend />
                <Step4and5Step
                    onInsuranceUpdate={handleInsuranceUpdate}
                    setInsuranceIncluded={setInsuranceIncluded}
                    insuranceIncluded={insuranceIncluded}
                />
                <Button
                    totalCostUSD={paymentData.totalCostUSD}
                    totalCostKZT={paymentData.totalCostKZT}
                    insuranceAmount={paymentData.insuranceAmount}
                    customDutyKZT={paymentData.customDutyKZT}
                    insuranceIncluded={insuranceIncluded}
                    parcelId={parcelId} // Передача ID посылки
                    servicesIds={servicesIds} // Передача ID услуг
                />

                <Footer/>
            </PageContainer>
        </AnimationRevealPage>
    );
};

export default ExpectedLink;
