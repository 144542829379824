import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getAuth } from "firebase/auth";
import {
    collection,
    addDoc,
    doc,
    setDoc,
    getDoc,
    getDocs,
    query,
    where,
    updateDoc,
} from "firebase/firestore";
import { db } from "../../FireBaseConfig";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(-70deg, #202020, #000000);
    font-family: 'Space Mono', monospace;
`;

const CardContainer = styled.div`
    position: relative;
    width: 420px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    overflow: hidden;
`;

const Circle = styled.div`
    position: absolute;
    border-radius: 50%;
    background: linear-gradient(120deg, #1D976C, #2C3E50);
    filter: blur(60px);
`;

const Circle1 = styled(Circle)`
    width: 300px;
    height: 300px;
    top: -50px;
    left: -100px;
    opacity: 0.8;
`;

const Circle2 = styled(Circle)`
    width: 240px;
    height: 240px;
    bottom: -50px;
    right: -80px;
    opacity: 0.8;
`;

const Title = styled.h1`
    color: #fff;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
`;

const PaymentCard = styled.div`
    background: linear-gradient(135deg, #1D976C, #93F9B9);
    border-radius: 15px;
    padding: 20px;
    color: #fff;
    font-family: 'Space Mono', monospace;
    margin-bottom: 20px;
    text-align: center;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
`;

const CardNumber = styled.div`
    font-size: 20px;
    letter-spacing: 2px;
    margin-bottom: 10px;
`;

const CardHolder = styled.div`
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 10px;
`;

const ExpiryCVV = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    font-family: 'Space Mono', monospace;
    background: none;
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;

    &::placeholder {
        color: rgba(255, 255, 255, 0.5);
    }

    &:focus {
        outline: none;
        border-bottom: 2px solid #fff;
    }
`;

const Button = styled.button`
    background: #27ae60;
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background 0.3s;

    &:hover {
        background: #1e8449;
    }
`;

const PaymentForm = () => {
    const location = useLocation();
    const navigate = useNavigate(); // Инициализация navigate
    const [amount, setAmount] = useState(location.state?.amount || '');
    const [cardNumber, setCardNumber] = useState('');
    const [cardHolder, setCardHolder] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [focused, setFocused] = useState('');
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(0);

    // Получаем данные посылки и услуг из location.state
    const parcelId = location.state?.parcelId || null;
    const servicesIds = location.state?.servicesIds || [];

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (currentUser) {
            setUser(currentUser);
            fetchUserBalance(currentUser.uid);
        }
    }, []);

    const fetchUserBalance = async (userId) => {
        try {
            const balanceDoc = await getDoc(doc(db, 'balances', userId));
            if (balanceDoc.exists()) {
                setBalance(balanceDoc.data().balance);
            } else {
                console.log('No balance document found for user.');
                await setDoc(doc(db, 'balances', userId), { balance: 0 });
                setBalance(0);
            }
        } catch (error) {
            console.error('Error fetching user balance:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const amountInCents = parseInt(amount, 10) * 100;
            const orderId = `order${Date.now()}`;

            const paymentData = {
                Key: 'MerchantEParcelinPay', // Ensure this key is correct
                SessionType: 'Pay',
                OrderId: orderId,
                Amount: amountInCents,
                PayInfo: `PAN=${cardNumber};EMonth=${expiry.split('/')[0]};EYear=${expiry.split('/')[1]};CardHolder=${cardHolder};SecureCode=${cvv};OrderId=${orderId};Amount=${amountInCents}`,
                CustomFields: `IP=127.0.0.1;Product=TestProduct`,
            };

            const queryString = Object.keys(paymentData)
                .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(paymentData[key])}`)
                .join('&');

            const proxyUrl = 'https://api.allorigins.win/get?url=';
            const apiUrl = `${proxyUrl}${encodeURIComponent(`https://sandbox3.payture.com/api/Pay?${queryString}`)}`;

            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            const data = await response.json();
            const decodedData = decodeURIComponent(data.contents);
            console.log('Payment API response:', decodedData);

            if (response.ok) {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(decodedData, 'text/xml');
                const payElement = xmlDoc.getElementsByTagName('Pay')[0];
                const success = payElement.getAttribute('Success');

                if (success === 'True') {
                    await addDoc(collection(db, 'payments'), {
                        userId: user.uid,
                        amount: amount,
                        date: new Date(),
                    });

                    const newBalance = balance + parseInt(amount, 10);
                    await setDoc(doc(db, 'balances', user.uid), {
                        balance: newBalance,
                    });

                    alert('Оплата успешно проведена');
                } else {
                    const errCode = payElement.getAttribute('ErrCode');
                    console.error(`Payment API error: ${decodedData}`);
                    alert(`Ошибка при проведении оплаты: ${errCode}`);
                }
            } else {
                console.error('Unexpected response from payment API:', decodedData);
                alert('Ошибка при проведении оплаты');
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Ошибка при проведении оплаты');
        }
        try {
            const amountInCents = parseInt(amount, 10) * 100;
            const orderId = `order${Date.now()}`;

            // Эмуляция успешного платежа
            const paymentSuccessful = true;

            if (paymentSuccessful) {
                if (parcelId) {
                    // Обновление статуса посылки
                    const querySnapshot = await getDocs(
                        query(collection(db, "parcels"), where("id", "==", parcelId))
                    );
                    if (!querySnapshot.empty) {
                        const documentRef = querySnapshot.docs[0].ref;
                        await updateDoc(documentRef, { status: "Оплачено" });
                        console.log(`Посылка с ID ${parcelId} обновлена на "Оплачено".`);
                    }
                }

                if (servicesIds && servicesIds.length > 0) {
                    // Обновление статусов услуг
                    const updatePromises = servicesIds.map((serviceId) => {
                        const serviceDoc = doc(db, "applications", serviceId);
                        return updateDoc(serviceDoc, { status: "Оплачено" });
                    });
                    await Promise.all(updatePromises);
                    console.log(`Услуги с ID ${servicesIds} обновлены на "Оплачено".`);
                }

                // Сохранение платежа в базу
                await addDoc(collection(db, 'payments'), {
                    userId: user.uid,
                    amount: amount,
                    date: new Date(),
                });

                alert('Оплата успешно проведена');
                navigate('/PaymentSuccess');
            } else {
                alert('Ошибка при проведении оплаты.');
            }
        } catch (error) {
            console.error('Error processing payment:', error);
            alert('Ошибка при проведении оплаты.');
        }
    };

    return (
        <AnimationRevealPage>
            <Container>
                <CardContainer>
                    <Circle1 />
                    <Circle2 />
                    <Title>Please enter your credit card details below</Title>
                    <PaymentCard>
                        <CardNumber>{cardNumber || '#### #### #### ####'}</CardNumber>
                        <CardHolder>{cardHolder || 'Your Name'}</CardHolder>
                        <ExpiryCVV>
                            <span>{expiry || 'MM/YY'}</span>
                            <span>{cvv || '###'}</span>
                        </ExpiryCVV>
                    </PaymentCard>
                    <Form>
                        <Input
                            type="text"
                            placeholder="Card Number"
                            maxLength="19"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="Card Holder"
                            value={cardHolder}
                            onChange={(e) => setCardHolder(e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="MM/YY"
                            maxLength="5"
                            value={expiry}
                            onChange={(e) => setExpiry(e.target.value)}
                        />
                        <Input
                            type="text"
                            placeholder="CVV"
                            maxLength="3"
                            value={cvv}
                            onChange={(e) => setCvv(e.target.value)}
                        />
                        <Button type="submit">Оплатить</Button>
                    </Form>
                </CardContainer>
            </Container>
        </AnimationRevealPage>
    );
};

export default PaymentForm;
